#root {
  height: 100vh;
}

.header-notification {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }

  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
}

.gx-sidebar-content {
  & > .ant-menu {
    & > .ant-menu-item {
      margin: 12px 0;

      & > .menu-icons {
        font-size: 20px;
      }

      & > span {
        font-size: 18px;
      }
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
}

.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}

.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
}

.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: #efefef;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

//CUSTOM STYLE CLASSES START

//margin & padding style
.m-0 {
  margin: 0;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 40px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-20p {
  margin-top: 20% !important;
}
.mr-5 {
  margin-right: 5px;
}
.mr-12 {
  margin-right: 12px;
}
.ml-75 {
  margin-left: 75px;
}
.mr-75 {
  margin-right: 75px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-10 {
  margin-left: 10px;
}

.p-0 {
  padding: 0;
}
//margin & padding style

// flex-box related style
.d-flex {
  display: flex;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}
// flex-box related style

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.fill-width {
  width: 100%;
}
//width classes

//cursor
.pointer {
  cursor: pointer;
}
//cursor

//border style
.b-0 {
  border: none;
}
//border style

// otp input style start

.otp-div > :first-child {
  margin: 5px !important;
  padding: 10px !important;
}

.otp-div {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  input {
    border-radius: 5px;
    border-color: #030846;
    padding: 10px !important;
    width: 35px !important;
    height: 35px !important;
  }
}

// otp input style end

//user card style start
.site-card-border-less-wrapper {
  padding: 30px;
  background: #ececec;
}
//user card style end

//save button style start
.btnsave {
  float: right;
  margin: 5px;
}
//save button style end

//card style start
.site-card-wrapper-workspace {
  padding: 15px;
  display: inline-block;
  margin-left: 15px;
}
.cardworkspace {
  width: 250px;
  text-align: center;
}
//card style end

//center element start
.center-element {
  text-align: center;
}
//center element end

//profile image style start
.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.profile-image-70 {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.profile-image-40 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
//profile image style end
.avatar-uploader > .ant-upload {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 0px 0 0px 24px;
  width: 1000px;
}

.site-layout-background {
  background: #fff;
}

//display inline style start
.inline-display {
  display: flex;
  align-items: center;
  place-items: inherit;
}
//display inline style end

//menu style start
.fontsize-18 {
  font-size: 18px !important;
}
.fontsize-20 {
  font-size: 20px !important;
}
.fontsize-22 {
  font-size: 22px !important;
}
.fontsize-50 {
  font-size: 50px !important;
}
.justify-text {
  text-align: justify;
  outline: '30px' !important ;
}

//menu style end

//profile style start
.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  margin-bottom: 25px;
}
.pointer{
  cursor: pointer;
}

//profile style end
.logo {
  font-size: 32px;
  font-family: 'Allan', cursive;
  margin-left: 6px;
}
.folder-dropdown {
  opacity: 0;
}
//CUSTOM STYLE CLASSES END
.sidebar-menu {
  &.ant-menu {
    .ant-menu-item {
      margin: 8px 0px;
    }
    .ant-menu-item:hover {
      background-color: #fff4f0;
      .folder-dropdown {
        opacity: 1;
      }
    }
  }
}

.folder-dropdown {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.folder-icon {
  margin-right: 0px !important;
}
.logo-icon {
  width: 120px;
  height: auto;
}
.main-title {
  color: #303179;
  font-weight: 600;
}
.sub-title {
  font-weight: 600;
  font-size: 16px;
}
.link-icon {
  color: #303179;
}
.workspace-name {
  color: #303179;
}
.create-link {
  font-weight: 600;
  font-size: 18px;
}
.ant-table {
  border: 1px solid #eae7dc;
}
.ant-table-tbody > tr > td {
  border-bottom: none;
}
.new-folder {
  padding: 0 30px 0 40px;
  margin: 12px 0px;
}
.separator {
  margin: 0px 5px;
}
.add-workspace {
  font-size: 16px;
}
.nav-row {
  background-color: white;
  padding: 30px 0px;
}
.ant-btn-sm {
  height: 30px;
}
.ant-modal-footer {
  .ant-btn {
    border-radius: 6px;
  }
}
.auth-text {
  margin-bottom: 0px;
  line-height: 24px;
}
.btn_link {
  color: #303179;
}
.logo_text {
  color: #303179;
}
.logo_text_2 {
  color: #ec6f5b;
}
.red_col {
  color: red;
}
.ant-select-selection-placeholder {
  text-align: left;
}
.ant-select-selection-item {
  flex: none;
}
.site-card-wrapper-analytics {
  padding: 15px;
  display: inline-block;
}
.line-through {
  text-decoration-line: line-through;
}
.ant-form-item-explain-error {
  margin-right: auto;
}
.ant-select-selection-placeholder {
  right: 0px !important;
}
.d-none {
  display: none !important;
}
.ant-layout-sider {
  overflow: auto !important;
}
.align-justify {
  text-align: justify !important;
}
.mt-8p {
  margin-top: 20%;
}
.height-200 {
  height: 200px;
}
.gx-main-content-wrapper {
  margin-bottom: 20px;
}
.anticon {
  padding: 0 0px !important;
}
@media (min-width: 992px) {
  .hamburger {
    display: none;
  }
}
@media (max-width: 992px) {
  .login-card {
    width: 50% !important;
  }
  .header-menu {
    display: none;
  }
  .nav-row {
    padding: 0px 0px;
  }
  .ant-drawer-right .ant-drawer-content-wrapper {
    padding: 0px;
    width: 245px !important;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px solid #ffffff !important;
  }
  .center-logo {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .ant-table .ant-table-tbody .anticon {
    font-size: 18px !important;
  }
  .member-table {
    padding: 16px 5px !important;
  }
}
.pl-50 {
  padding-left: 50px !important;
}


.img-upload{
  position: relative;
  &:hover .upload-text{
    display: block;
  }
}
.upload-text{
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 45%;
    display: none;
    color: #303179;
    font-weight: bold;
}